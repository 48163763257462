export default (element: string) => {
  let sizes = {
    width: 0,
    height: 0,
    left: 0,
    top: 0,
  }
  const updateSizes = () => {
    const elem = document.querySelector(element)
    if (elem && elem instanceof HTMLElement) {
      sizes = {
        width: elem.offsetWidth,
        height: elem.offsetHeight,
        left: elem.offsetLeft,
        top: elem.offsetTop,
      }
    }
  }
  window.addEventListener('resize', updateSizes)
  updateSizes()
  return sizes
}
