import adSizes from './adSizes'
import windowSize from './windowSize'
import getElementSizes from './getElementSizes'
import { BannerType } from '../types/adTypes'

export default () => {
  const [width] = windowSize()
  const { left: offsetLeft } = getElementSizes('main')

  const filter = (type: BannerType): string[] => {
    const { position, formats } = adSizes[type]
    if (position === 'center') {
      return formats
        .filter((format) => {
          return format.width >= width || format.exclude === true
        })
        .map((format) => {
          return format.format
        })
    }
    if (position === 'side') {
      return formats
        .filter((format) => {
          return format.width >= offsetLeft || format.exclude === true
        })
        .map((format) => {
          return format.format
        })
    }
    return []
  }

  let items: string[] = []
  Object.keys(adSizes).forEach((e) => {
    const key = e as BannerType
    const filtered = filter(key)
    items = items.concat(filtered)
  })

  return items
}
