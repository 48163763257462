export default () => {
  let size = [0, 0]

  const updateSize = () => {
    size = [window.innerWidth, window.innerHeight]
  }
  window.addEventListener('resize', updateSize)
  updateSize()

  return size
}
