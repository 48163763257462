import { Container, makeStyles, Typography } from '@material-ui/core'
import { Link } from 'gatsby-theme-material-ui'
import React, { forwardRef } from 'react'

export type FooterProps = unknown

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  footer: {
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(4),
  },
  footerLink: {
    color: 'white',
    marginRight: theme.spacing(2),
  },
}))

const Footer = forwardRef<HTMLDivElement, FooterProps>((props, ref) => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Container className={classes.container} ref={ref}>
        <Typography>
          <Link className={classes.footerLink} to="/impressum/">
            Impressum
          </Link>
          <Link className={classes.footerLink} to="/datenschutz/">
            Datenschutz
          </Link>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            to="#"
            className={classes.footerLink}
            onClick={(event) => {
              event.preventDefault()

              // eslint-disable-next-line no-underscore-dangle
              ;(window as any)._sp_?.gdpr?.loadPrivacyManagerModal(533174)
            }}
          >
            Consent Manager
          </Link>
        </Typography>
        <Typography className={classes.footerLink}>
          © MAIRDUMONT. Alle Rechte vorbehalten.
        </Typography>
      </Container>
    </footer>
  )
})

Footer.displayName = 'Footer'

export default Footer
