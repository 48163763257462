import React, { forwardRef, PropsWithChildren } from 'react'
import { BannerType } from '../types/adTypes'

export const adSizes = {}

export type AdProps = {
  id: string
  type: BannerType
  exclude?: boolean
}

const Ad = forwardRef<HTMLDivElement, PropsWithChildren<AdProps>>(
  (props, ref) => {
    const { id } = props

    return <div ref={ref} id={id} />
  },
)

export default Ad
