import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import config from '../../config.json'
import getJsonLd from '../utils/getJsonLd'

export type SeoImage = {
  /**
   * relative image path
   */
  path: string
  caption: string
  /**
   * max 420 characters
   */
  alt: string
  width?: number
  height?: number
}

export type SeoProps = {
  path: string
  /**
   * max 60 characters
   */
  title: string
  disableTitleSuffix?: boolean
  /**
   * max 120 characters
   */
  description: string
  images?: SeoImage[]
  noIndex?: boolean
}

const {
  language,
  name,
  seo: { titleSuffix, locale },
} = config

const Seo = ({
  path,
  title: propsTitle,
  disableTitleSuffix,
  description,
  images: propsImages,
  noIndex,
}: SeoProps) => {
  const { site } = useStaticQuery<GatsbyTypes.SeoComponentQuery>(graphql`
    query SeoComponent {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const siteUrl = site?.siteMetadata?.siteUrl

  if (!siteUrl) {
    throw Error('Invalid siteUrl')
  }

  const url = `${siteUrl}${path === '/' ? '' : path}`

  const title = `${propsTitle}${disableTitleSuffix ? '' : titleSuffix}`

  const images = propsImages?.map((image) => ({
    ...image,
    url: `${siteUrl}${image.path}`,
  }))

  return (
    <Helmet
      // https://github.com/gatsbyjs/gatsby/tree/master/packages/gatsby-plugin-react-helmet#titles-dont-appear-when-opening-in-the-background-while-using-gatsby-plugin-offline
      defer={false}
      htmlAttributes={{
        lang: language,
      }}
    >
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />

      {/* https://ogp.me/#metadata */}
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />

      {/* https://ogp.me/#optional */}
      <meta property="og:description" content={description} />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content={name} />

      {
        // https://ogp.me/#structured
        images &&
          images.flatMap(({ url: imageUrl, alt, width, height }) => [
            <meta
              key={`og:image-${imageUrl}`}
              property="og:image"
              content={imageUrl}
            />,
            <meta
              key={`og:image:alt-${imageUrl}`}
              property="og:image:alt"
              content={alt}
            />,
            ...(width
              ? [
                  <meta
                    key={`og:image:width-${imageUrl}`}
                    property="og:image:width"
                    content={`${width}`}
                  />,
                ]
              : []),
            ...(height
              ? [
                  <meta
                    key={`og:image:height-${imageUrl}`}
                    property="og:image:height"
                    content={`${height}`}
                  />,
                ]
              : []),
          ])
      }

      {/* https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/summary */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      {images?.length && [
        <meta
          key="twitter:image"
          name="twitter:image"
          content={images[0].url}
        />,
        <meta
          key="twitter:image:alt"
          name="twitter:image:alt"
          content={images[0].alt}
        />,
      ]}

      <script type="application/ld+json">
        {JSON.stringify(
          getJsonLd({
            siteUrl,
            url,
            title,
            description,
            ...(images?.length && {
              image: images[0],
            }),
          }),
        )}
      </script>

      {noIndex && <meta name="robots" content="noindex,nofollow" />}
    </Helmet>
  )
}

export default Seo
