export type Format = {
  format: string
  width: number
  height: number
  exclude?: boolean
}

export type Banner = {
  position: 'center' | 'side'
  formats: Format[]
}

export type BannerSizes = {
  banner: Banner
  banner2: Banner
  pubperform: Banner
  sky: Banner
  topmobile: Banner
  topmobile2: Banner
  topmobile3: Banner
  topmobile4: Banner
  stickyfooter: Banner
}

const adSizes: BannerSizes = {
  banner: {
    position: 'center',
    formats: [
      { format: 'leaderboard', width: 728, height: 90 },
      {
        format: 'doubleleaderboard',
        width: 728,
        height: 180,
      },
      { format: 'billboard', width: 970, height: 250 },
      { format: 'wallpaper', width: 848, height: 90 },
      { format: 'fireplace', width: 968, height: 90 },
      { format: 'directionad', width: 1400, height: 250 },
    ],
  },
  banner2: {
    position: 'center',
    formats: [
      { format: 'leaderboard', width: 728, height: 91 },
      {
        format: 'doubleleaderboard',
        width: 728,
        height: 181,
      },
    ],
  },
  pubperform: {
    position: 'center',
    formats: [{ format: 'qualityfooter', width: 970, height: 250 }],
  },
  sky: {
    position: 'side',
    formats: [
      { format: 'sitebar', width: 500, height: 600 },
      { format: 'doubledynamicsitebar', width: 500, height: 600 },
      { format: 'skyscraper', width: 200, height: 600 },
      { format: 'halfpagead', width: 300, height: 600 },
    ],
  },
  topmobile: {
    position: 'center',
    formats: [
      { format: 'mobilead6x1', width: 320, height: 50 },
      { format: 'mobilead4x1', width: 320, height: 75 },
      { format: 'mobilead3x1', width: 320, height: 100 },
      { format: 'mobilead2x1', width: 320, height: 150 },
      { format: 'mobilesitebar', width: 400, height: 50 },
      { format: 'mobilebrandbooster', width: 320, height: 481 },
      { format: 'fullbanner', width: 468, height: 60 },
      { format: 'mobileleaderboard', width: 728, height: 90 },
      { format: 'mobilepresentermrec', width: 320, height: 51 },
    ],
  },
  topmobile2: {
    position: 'center',
    formats: [
      { format: 'mobilead6x1', width: 320, height: 50 },
      { format: 'mobilead4x1', width: 320, height: 75 },
      { format: 'mobilead3x1', width: 320, height: 100 },
      { format: 'mobilead2x1', width: 320, height: 150 },
    ],
  },
  topmobile3: {
    position: 'center',
    formats: [
      { format: 'mobilead6x1', width: 320, height: 50 },
      { format: 'mobilead4x1', width: 320, height: 75 },
      { format: 'mobilead3x1', width: 320, height: 100 },
      { format: 'mobilead2x1', width: 320, height: 150 },
    ],
  },
  topmobile4: {
    position: 'center',
    formats: [
      { format: 'mobilead6x1', width: 320, height: 50 },
      { format: 'mobilead4x1', width: 320, height: 75 },
      { format: 'mobilead3x1', width: 320, height: 100 },
      { format: 'mobilead2x1', width: 320, height: 150 },
    ],
  },
  stickyfooter: {
    position: 'center',
    formats: [{ format: '', width: 0, height: 0 }],
  },
}

export default adSizes
