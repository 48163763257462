import config from '../../config.json'

const {
  seo: {
    inLanguage,
    organization: {
      name: organizationName,
      description: organizationDescription,
      logo: { path: logoPath, width: logoWidth, height: logoHeight },
    },
    website: { name: websiteName, description: websiteDescription },
  },
} = config

export type GetJsonLdParams = {
  siteUrl: string
  url: string
  title: string
  description: string
  image?: {
    url: string
    caption: string
    width?: number
    height?: number
  }
  datePublished?: string
  dateModified?: string
}

export default ({
  siteUrl,
  url,
  title,
  description,
  image,
}: GetJsonLdParams) => {
  const {
    url: imageUrl,
    caption: imageCaption,
    width: imageWidth,
    height: imageHeight,
  } = image || {}

  const organizationId = `${siteUrl}/#organization`
  const logoId = `${siteUrl}/#logo`
  const websiteId = `${siteUrl}/#website`
  const primaryImageId = `${url}/#primaryimage`
  const webpageId = `${url}/#webpage`

  return {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        '@id': organizationId,
        url: siteUrl,
        name: organizationName,
        description: organizationDescription,
        logo: {
          '@type': 'ImageObject',
          '@id': logoId,
          inLanguage,
          url: `${siteUrl}${logoPath}`,
          caption: organizationName,
          width: logoWidth,
          height: logoHeight,
        },
        image: {
          '@id': logoId,
        },
      },
      {
        '@type': 'WebSite',
        '@id': websiteId,
        inLanguage,
        url: siteUrl,
        name: websiteName,
        description: websiteDescription,
        publisher: {
          '@id': organizationId,
        },
        copyrightHolder: {
          '@id': organizationId,
        },
      },
      ...(image
        ? [
            {
              '@type': 'ImageObject',
              '@id': primaryImageId,
              inLanguage,
              url: imageUrl,
              caption: imageCaption,
              width: imageWidth,
              height: imageHeight,
            },
          ]
        : []),
      {
        '@type': 'WebPage',
        '@id': webpageId,
        inLanguage,
        url,
        name: title,
        description,
        isPartOf: { '@id': websiteId },
        potentialAction: [
          {
            '@type': 'ReadAction',
            target: [url],
          },
        ],
        ...(image && {
          primaryImageOfPage: {
            '@id': primaryImageId,
          },
        }),
      },
    ],
  }
}
