import {
  AppBar,
  Box,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { Button, Link } from 'gatsby-theme-material-ui'
import React, { forwardRef, useState } from 'react'
import Logo from '../assets/svg/logo.svg'

export type NavigationProps = unknown

const navigationEntries = [
  {
    title: 'Baedeker.Das Original',
    path: '/baedeker-das-original/',
  },
  {
    title: 'Baedeker.SMART',
    path: '/baedeker-smart/',
  },
  {
    title: 'Reiseführer',
    path: '/reisefuehrer/',
  },
  {
    title: 'Verlag',
    path: '/verlag/',
  },
  {
    title: 'Empfehlungen',
    path: '/empfehlungen/',
  },
] as const

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
  },
  desktopButton: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(2),
    },
  },
  listItem: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
}))

const Navigation = forwardRef<HTMLDivElement, NavigationProps>((props, ref) => {
  const classes = useStyles()

  const [drawerOpen, setDrawerOpen] = useState(false)

  const theme = useTheme()
  const mdUp = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <AppBar position="sticky" ref={ref}>
      <Container>
        <Toolbar disableGutters className={classes.toolbar}>
          <Button to="/">
            <Logo fill="white" style={{ height: 'auto', width: 200 }} />
          </Button>

          {mdUp ? (
            <Box>
              {navigationEntries.map(({ title, path }) => (
                <Button
                  key={title}
                  className={classes.desktopButton}
                  to={path}
                  color="inherit"
                >
                  {title}
                </Button>
              ))}
            </Box>
          ) : (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="Menü"
                onClick={() => setDrawerOpen(true)}
              >
                <MenuIcon />
              </IconButton>

              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
              >
                <List disablePadding>
                  {navigationEntries.map(({ title, path }) => (
                    <ListItem
                      key={title}
                      component={Link}
                      to={path}
                      button
                      divider
                      className={classes.listItem}
                    >
                      <ListItemText primary={title} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
})

Navigation.displayName = 'Navigation'

export default Navigation
