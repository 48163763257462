import { Box, Container, Grid, makeStyles } from '@material-ui/core'
import { PageProps } from 'gatsby'
import React, {
  forwardRef,
  PropsWithChildren,
  useCallback,
  useEffect,
} from 'react'
import { useCookies } from 'react-cookie'
import { Helmet } from 'react-helmet-async'
import Footer from './footer'
import Navigation from './navigation'
import Seo, { SeoProps } from './seo'
import Ad from './ad'
import adsToExclude from '../utils/adsToExclude'

export type LayoutProps = Pick<PageProps, 'location'> & {
  seoProps: Omit<SeoProps, 'path'>
  visual?: unknown
  showAds?: boolean
}

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    flexGrow: 1,
    overflowX: 'visible',
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  },
  site: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  sky: {
    position: 'absolute',
    top: theme.spacing(4),
    left: `100%`,
  },
}))

const cookieName = 'gatsby-gdpr-google-analytics'

const Layout = forwardRef<HTMLDivElement, PropsWithChildren<LayoutProps>>(
  ({ location, seoProps, children, visual, showAds = true, ...rest }, ref) => {
    const { pathname: path } = location

    const classes = useStyles()
    const [, setCookie] = useCookies([cookieName])

    const metaTagSystemCmpConsentAvailable = useCallback(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const consent = window.SDG.Publisher.getCustomVendorConsent(
        'Google Analytics',
      )
      setCookie(cookieName, consent)
    }, [setCookie])

    useEffect(() => {
      window.addEventListener(
        'metaTagSystemCmpConsentAvailable',
        metaTagSystemCmpConsentAvailable,
      )

      return () => {
        window.removeEventListener(
          'metaTagSystemCmpConsentAvailable',
          metaTagSystemCmpConsentAvailable,
        )
      }
    }, [metaTagSystemCmpConsentAvailable])

    useEffect(() => {
      const exclude = adsToExclude()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (window.SDG) {
        if (exclude && exclude.length > 0) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.SDG.Publisher.blockFormats(exclude)
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.SDG.Publisher.unregisterSlot('banner', true)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const bannerTop = window.SDG.Publisher.registerSlot(
          'banner',
          'bannerTop',
        )
        if (bannerTop) {
          bannerTop.load()
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.SDG.Publisher.unregisterSlot('sky', true)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const skyRight = window.SDG.Publisher.registerSlot('sky', 'skyRight')
        if (skyRight) {
          skyRight.load()
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.SDG.Publisher.unregisterSlot('pubperform', true)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const bannerBottom = window.SDG.Publisher.registerSlot(
          'pubperform',
          'bannerBottom',
        )
        if (bannerBottom) {
          bannerBottom.load()
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.SDG.Publisher.unregisterSlot('topmobile', true)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const bannerTopMobile = window.SDG.Publisher.registerSlot(
          'topmobile',
          'bannerTopMobile',
        )
        if (bannerTopMobile) {
          bannerTopMobile.load()
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.SDG.Publisher.unregisterSlot('stickyfooter', true)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const bannerBottomMobile = window.SDG.Publisher.registerSlot(
          'stickyfooter',
          'bannerBottomMobile',
        )
        if (bannerBottomMobile) {
          bannerBottomMobile.load()
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.SDG.Publisher.finalizeSlots()
      }
    }, [])

    return (
      <div className={classes.site}>
        <Helmet>
          <script
            id="metaTag"
            src={`https://cdn.stroeerdigitalgroup.de/metatag/${
              process.env.GATSBY_META_TAG_CHANNEL || 'live'
            }/mdnx_baedeker.com/metaTag.min.js`}
          />
        </Helmet>
        <Seo {...{ ...seoProps, path }} />
        <Navigation />
        {visual}
        {children && (
          <Container
            component="main"
            className={classes.container}
            {...rest}
            ref={ref}
          >
            {showAds && (
              <Grid container justify="center">
                <Grid item>
                  <Ad id="bannerTop" type="banner" />
                  <Ad id="bannerTopMobile" type="topmobile" />
                </Grid>
              </Grid>
            )}
            {children}
            <Box className={classes.sky}>
              <Ad id="skyRight" type="sky" />
            </Box>
          </Container>
        )}
        {showAds && (
          <Grid container justify="center">
            <Grid item>
              <Ad id="bannerBottom" type="pubperform" />
              <Ad id="bannerBottomMobile" type="stickyfooter" />
            </Grid>
          </Grid>
        )}
        <Footer />
      </div>
    )
  },
)

Layout.displayName = 'Layout'

export default Layout
